import React from 'react';
import { Button, message } from 'antd';

function App() {
  const clicked = () => {
    const result = window.frontAPI.getDefaults();
    message.success(`Shop version is ${result.shop_version}`);
  };

  return (
    <Button type="primary" onClick={clicked}>Hello</Button>
  );
}

export default App;
